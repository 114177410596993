<header [attr.nf]="this.config.$isNotFoundPage()">
  <div class="header__burger" [attr.data-visible]="this.auth.$isAuth()">
    <button
      (click)="this.config.toggleMenuStatus()"
      [attr.data-open]="this.config.$menuIsOpen()"
    >
      <span class="header__burger__line header__burger__line1"></span>
      <span class="header__burger__line header__burger__line2"></span>
      <span class="header__burger__line header__burger__line3"></span>
    </button>
  </div>
  <div class="header__logo">
    <a routerLink="/" (click)="this.config.closeMenu()">
      <img src="images/logo.svg" width="84.6" height="50" />
    </a>
  </div>
  <div class="header-me">
    @if (this.auth.$user().id) {
      <tui-hosted-dropdown
        class="t-wrapper-flex"
        [content]="profileMenu"
        [(open)]="isOpenProfileDropdown"
        [tuiDropdownOffset]="10"
      >
        <tui-avatar
          class="header-me__avatar"
          [avatarUrl]="auth.$avatar()"
          size="s"
          [rounded]="true"
          [text]="auth.$user().full_name || auth.$user().username || ''"
        />

        <tui-line-clamp
          class="header-me__person"
          [content]="auth.$user().full_name || auth.$user().username || ''"
          [linesLimit]="1"
        />
        <tui-svg src="tuiIconChevronDown" />
      </tui-hosted-dropdown>
    } @else {
      <a
        routerLink="/auth"
        class="header-me__auth button"
        routerLinkActive="header-me__auth--hidden"
        ><span>Войти</span></a
      >
    }
  </div>

  <ng-template #profileMenu let-close="close">
    <spomen-me (close)="close()" />
  </ng-template>
</header>

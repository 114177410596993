<tui-root
  scrollNearEnd
  (nearEnd)="this.nearEnd($event)"
  class="root-wrapper"
  [attr.data-loading]="this.auth.$$isLoading | async"
  [attr.data-nf]="this.config.$isNotFoundPage()"
>
  <spomen-header [attr.data-menu]="this.config.$menuIsOpen()" />
  @if ((this.auth.$$isLoading | async) && !this.config.$isRefusedPage()) {
    <tui-loader size="xxl" @inOut500 />
  } @else if (!this.config.$isRefusedPage()) {
    @if (this.auth.$isAuth()) {
      <div class="wrapper">
        <spomen-nav [attr.data-hidden]="this.config.$menuIsOpen()" />
        <main [attr.nf]="this.config.$isNotFoundPage()">
          <router-outlet />
        </main>
      </div>
    } @else {
      <router-outlet />
    }

    @if (this.config.$menuIsOpen()) {
      <spomen-menu @inOut200 />
    }
  }

  @if (this.config.$isRefusedPage()) {
    <spomen-oops @inOut200 />
  }
</tui-root>
